import React from 'react';

class LayoutInspiration extends React.Component {
    render() {
        return <div>
            <h1>Coming Soon</h1>
            <a href="./">Homepage</a>
            </div>
    }
}

export default LayoutInspiration