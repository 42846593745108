import React, { createRef } from 'react'
import SaveCanvas from './saveCanvas'
import sendApiData from './sendApiData';

function randInt(number){return Math.floor(Math.random()*number)}

function pickIngredients(Ingredients) {
  
  let listsToPickFrom = [0,1,2,3,4,0,1,2,3,4]
  listsToPickFrom.splice(Math.floor(Math.random()*5),1)
  let toDisplay = []
  let usedGroups= []
  for (let listindex of listsToPickFrom) {
      while (true) {
          let randomItem = Ingredients[listindex][randInt(Ingredients[listindex].length)]
          if (usedGroups.includes(randomItem.group) && randomItem.group!==undefined) {continue}
          toDisplay.push(randomItem.value || randomItem.color)
          usedGroups.push(randomItem.group)
          break
      }
  }
  return shuffle(toDisplay)
}
function shuffle(array) {
  var currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}
class TicTacToeCanvas extends React.Component {
  constructor() {
      super()
      this.state={canvasRef:createRef(),text:[],timesReloaded:0}
      this.draw=this.draw.bind(this)
      this.saving=this.saving.bind(this)
      this.reloadCanvas=this.reloadCanvas.bind(this)   }
      saving() {
        console.log("SAVING")
        sendApiData("storetictactoe",{data:this.state.text,reload:this.state.timesReloaded})
        this.setState({timesReloaded:0})
      }
      reloadCanvas() {
        this.setState({timesReloaded:this.state.timesReloaded+1})
        this.draw()
      }
      componentDidMount(){
        this.draw()
      }
  draw() {
    
    const canvas = this.state.canvasRef.current
    const context = canvas.getContext('2d')
    //Our first draw
    context.font = "bold "+"30px Courier New";
    let textToDisplay = pickIngredients(this.props.ingredients)
    let i = 1
    let grid = [[textToDisplay[0],textToDisplay[1],textToDisplay[2]],[textToDisplay[3],textToDisplay[4],textToDisplay[5]],[textToDisplay[6],textToDisplay[7],textToDisplay[8]]]
    this.setState({text:grid})
    function getSquareSize(fontsize,margin) {
      context.font = "bold "+fontsize+"px Courier New";
        let squaresize=0
        for (let x of [0,1,2]) {
          for (let y of [0,1,2]) {
            let textlist=grid[x][y].split(" ")
            for (let text of textlist) {
              text = text.replace("~"," ")
          if (context.measureText(text).width+margin*2 > squaresize) {
            squaresize = context.measureText(text).width+margin*2
          }}
          i++
        }
      } return squaresize + margin*2
    }
    let fontsize = 100
    let margin=5
    let size=500
    while (getSquareSize(fontsize, margin) > size/3) {
      fontsize--
    }
    while (getSquareSize(fontsize, margin) <= size/3) {
      margin++
    }
    margin--

    context.font = "bold "+fontsize+"px Courier New";
    let squaresize= getSquareSize(fontsize,margin)
    console.log(squaresize,fontsize,margin)
    context.canvas.width = 3*squaresize+150
    context.canvas.height = 3*squaresize+150
    context.fillStyle = '#ffffff'
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = '#000000'
    i=1
    let xpos = 75
    for (let x of [0,1,2]) {
      let ypos=125
      
      for (let y of [0,1,2]) {
        let textlist=grid[x][y].split(" ")
        let spacevert = squaresize-textlist.length*30
        let boxvert=spacevert/2
        for (let text of textlist) {
          text = text.replace("~"," ")
          context.font = "bold "+fontsize+"px Courier New";
        let space=squaresize-context.measureText(text).width
        context.fillText(text,xpos+(space/2),ypos+boxvert)
        boxvert+=30
        }
      i++
      ypos+=squaresize
    }
    xpos+=squaresize
  }
let a=75
let b=squaresize+a
let c=2*squaresize+a
let d=3*squaresize+a
let e = 20
context.beginPath();
context.moveTo(a, a+e-3);
context.lineTo(a,d+e);
context.lineTo(d,d+e);
context.lineTo(d,a+e);
context.lineTo(a,a+e);
context.lineWidth = 5;
context.stroke();
context.beginPath();
context.moveTo(a, a+e);
context.lineTo(a,b+e);
context.lineTo(d,b+e);
context.lineTo(d,c+e);
context.lineTo(a,c+e);
context.lineTo(a,d+e);
context.lineTo(b,d+e);
context.lineTo(b,a+e);
context.lineTo(c,a+e);
context.lineTo(c,d+e);
context.lineWidth = 5;
context.stroke();

context.beginPath();
context.moveTo(0, 0);
context.lineTo(0,context.canvas.height);
context.lineTo(context.canvas.width, context.canvas.height);
context.lineTo(context.canvas.width,0);
context.lineTo(0,0)
context.lineWidth = 40;
context.strokeStyle = "#84E095"
context.stroke()

context.beginPath();
context.moveTo(0, 0);
context.lineTo(0,context.canvas.height);
context.lineTo(context.canvas.width, context.canvas.height);
context.lineTo(context.canvas.width,0);
context.lineTo(0,0)
context.lineWidth = 20;
context.strokeStyle = "#f1aec9"
context.stroke()

context.fillStype = "#000000"

let title="snap.scrap.repeat"
let subtitle="Scrapbook Tic-Tac-Toe Challenge"
let footer="Generated by snapscraprepeat.com"
context.font = "bold 20px Montserrat"
let space=context.canvas.width-context.measureText(title).width
context.fillText(title,(space)/2,45)
context.font = "bold 30px Montserrat"
space=context.canvas.width-context.measureText(subtitle).width
context.fillText(subtitle,(space)/2,80)
context.font = "bold 15px Montserrat"
space=context.canvas.width-context.measureText(footer).width
context.fillText(footer,(space)/2,context.canvas.height-30)
document.getElementById("canvasImage").src=document.getElementById("resetCanvas").toDataURL()
}
returnFalse(e){e.preventDefault();return false}
  render(){
  return <><canvas id="resetCanvas" ref={this.state.canvasRef} {...this.props}/><img onContextMenu={this.returnFalse} id="canvasImage" src=""></img><SaveCanvas onClick={this.saving}/><button onClick={this.reloadCanvas}>Generate New TicTacToe Challenge</button>
  <form method="get" action="./">
  <button type="submit">Homepage</button>
</form></>
  }
}

export default TicTacToeCanvas