import './App.css';
import React from 'react';
import SaveCanvas from './saveCanvas';
import getApiData from './getApiData';
import TicTacToeCanvas from './tictactoecanvas.js';

class TicTacToe extends React.Component {
  constructor() {
    super()
    this.state = {
      timesReloaded:0,
      Ingredients:null
    }
    this.reloadCanvas=this.reloadCanvas.bind(this)
  }

  async componentDidMount() {
    console.log("GETTING DATA")
    let Ingredients = await getApiData("recipe")
    this.setState({Ingredients:Ingredients})
  }
  reloadCanvas() {
    this.setState({timesReloaded:this.state.timesReloaded+1})
  }
  render(){
    if (this.state.Ingredients){ 
    return (
    <div className="App">
      <div>
      <h2>Here's a Scrapbook Tic-Tac-Toe Challenge for you...</h2>
        <TicTacToeCanvas ingredients={this.state.Ingredients}/>
      </div>
    </div>
  )}else{
    return <div className="App">

  </div>
  };}
}
 
export default TicTacToe;
