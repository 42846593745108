import React, { Component } from 'react'

export class SaveCanvas extends Component {
    constructor(props) {
        super(props)
        this.saveCanvas=this.saveCanvas.bind(this)
    }
    
    saveCanvas(){
        var canvas = document.getElementById("resetCanvas");
        var url = canvas.toDataURL("image/png");
        var link = document.createElement('a');
        link.download = 'recipe.png';
        link.href = url;
        link.click();
        this.props.onClick()
      }
    render() {
        return (
            <button onClick={this.saveCanvas}>Download Challenge </button>
        )
    }
}

export default SaveCanvas
