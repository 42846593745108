import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Router, Switch, Route} from "react-router-dom";
import history from "./history.js";
import TicTacToe from "./tictactoe.js";
import LayoutInspiration from "./layoutInspiration"
import Homepage from "./homepage"
import About from "./about"
console.log("test")
ReactDOM.render(
  <React.StrictMode>
    <img src="/webbanner.jpg" style={{width:"100%"}}></img>
    <Router history={history}>
        <Switch>
          <Route path="/recipe"><App /></Route>
          <Route path="/tictactoe"><TicTacToe /></Route>
          <Route path="/layout"><LayoutInspiration /></Route>
          <Route path="/about"><About /></Route>
          <Route path="/"><Homepage /></Route>
        </Switch>
    </Router>
     </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
