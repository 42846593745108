import React, { Component } from 'react'

export class Homepage extends Component {
    render() {
        return (
            <div>
                <a href="./recipe">Recipe Generator</a>
                <a href="./tictactoe">Tic-Tac-Toe Generator</a>
                <a href="./layout">Layout Inspiration</a>
                <a href="./about">About</a>
            </div>
        )
    }
}

export default Homepage
