import './App.css';
import React from 'react';
import getApiData from './getApiData';
import Canvas from './canvas';

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      Ingredients:null
    }
  }

  async componentDidMount() {
    console.log("GETTING DATA")
    let Ingredients = await getApiData("recipe")
    this.setState({Ingredients:Ingredients})
  }
  render(){
    console.log(this.state.lists)
    if (this.state.Ingredients){
    return (
     
    <div className="App">
      <div>
      <h2>Here's a Scrapbook Recipe Challenge for you...</h2>
        <Canvas ingredients={this.state.Ingredients}/>
      </div>
    </div>
  )}else{return <div className="App">
  </div>};}
}
 
export default App;
