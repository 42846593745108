//This is how everything on the frontend will interact with the API and hence the database.
const fetch = require("node-fetch");

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.0.20:3001/';
async function sendApiData(url,body) {
    const response = await fetch(API_URL+url, {method: 'POST',headers:{"Content-Type":"application/json"}, body:JSON.stringify(body)});
    const data = await response.json();
    return data;
}



export default sendApiData;