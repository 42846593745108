import React, { createRef } from 'react'
import SaveCanvas from './saveCanvas'
import sendApiData from './sendApiData';

function getColors(colors,numberOfColors,uniqueGroups) {
  let ColorsToReturn=[]
  let groupsUsed=[]
  let i=0
  while (i<numberOfColors) {
      let index = Math.floor(Math.random()*colors.length)
      if (uniqueGroups && groupsUsed.includes(colors[index].group)) {
          continue
      }
      i++
      ColorsToReturn.push(colors[index].color)
      groupsUsed.push(colors[index].group)
  }
  return ColorsToReturn
}

function listToText(list) {
  if (list.length===1) {return list[0]}
  let lastText=list.pop()
  return list.join(", ") + " and " + lastText
}

function pickNumColors() {
  let randomNumber = Math.random()
  if (randomNumber<0.5) {
      return 1
  }
  if (randomNumber<0.9) {
      return 2
  }
  return 3
}

function pickNumIngredients() {
  let randomNumber = Math.random()
  if (randomNumber<0.4) {
      return 4
  }
  if (randomNumber<0.6) {
      return 3
  }
  if (randomNumber<0.9) {
      return 5
  }
  if (randomNumber<0.95) {
      return 2
  }
  return 6
  
}

function randInt(number){return Math.floor(Math.random()*number)}

function pickIngredients(Ingredients) {
  let numIngredients = pickNumIngredients()
  console.log(`INGREDIENTS: ${numIngredients}`)
  let randomNumbersNeeded=numIngredients%5
  let numbers=[0,1,2,3,4]
  let listsToPickFrom = numIngredients<5?[]:[0,1,2,3,4]
  console.log(listsToPickFrom)
  for (let i=0;i<randomNumbersNeeded;i++) {
      let index=randInt(numbers.length)
      listsToPickFrom.push(numbers[index])
      numbers.splice(index,1)
  }
  console.log(`listsToPickFrom: ${listsToPickFrom}`)
  let toDisplay = []
  let usedGroups= []

  if (listsToPickFrom.indexOf(0) !== -1) {
      toDisplay.push(listToText(getColors(Ingredients[0],pickNumColors(),true)))
  }
  for (let listindex of listsToPickFrom) {
      if (listindex===0) {continue}
      while (true) {
          console.log(Ingredients.length,listindex)
          let randomItem = Ingredients[listindex][randInt(Ingredients[listindex].length)]
          if (usedGroups.includes(randomItem.group) && randomItem.group!==undefined) {continue}
          toDisplay.push(randomItem.value)
          usedGroups.push(randomItem.group)
          break
      }
  }
  console.log(`toDisplay: ${toDisplay}`)
  return toDisplay
}

class Canvas extends React.Component {
  constructor() {
      super()
      this.state={canvasRef:createRef(),text:[],timesReloaded:0}
      this.draw=this.draw.bind(this)
      this.saving=this.saving.bind(this)
      this.reloadCanvas=this.reloadCanvas.bind(this)  }
  saving() {
    console.log("SAVING")
    sendApiData("storerecipes",{data:this.state.text,reload:this.state.timesReloaded})
    this.setState({timesReloaded:0})
  }
  reloadCanvas() {
    this.setState({timesReloaded:this.state.timesReloaded+1})
    this.draw()
  }
  componentDidMount() {
    this.draw()
  }
  draw() {
  
    const canvas = this.state.canvasRef.current
    const context = canvas.getContext('2d')
    
    //Our first draw
    context.font = "bold "+"30px Courier New";
    let textToDisplay = pickIngredients(this.props.ingredients)
    this.setState({text:textToDisplay})
    let widthToBe = 0
    let i = 1
    let height=150
    for (const line of textToDisplay) {
      if (context.measureText(line).width+30 > widthToBe) {
        widthToBe = context.measureText(line).width+100
      }
      i++
      height+=30
    }

    let title="snap.scrap.repeat"
    let subtitle="Scrapbook Recipe Challenge"
    let instructions = "Create a scrapbook layout using ALL of the following..."
    let footer="Generated by snapscraprepeat.com"

    context.font = "bold 30px Montserrat"
    let space=context.measureText(subtitle).width + 50
    widthToBe=Math.max(space,widthToBe)
    

    widthToBe = Math.max(height,widthToBe)
    context.canvas.width = widthToBe
    context.canvas.height = widthToBe
    context.fillStyle = '#ffffff'
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = '#000000'
    i=1
    for (const line of textToDisplay) {
      console.log(line,0,i*30)
      context.font = "bold 30px Courier New";
      let space=context.canvas.width-context.measureText("* "+line).width
      context.fillText("* "+line,(space)/2,i*30+145)
      i++
    }
    context.font = "bold 20px Montserrat"
    space=context.canvas.width-context.measureText(title).width
    context.fillText(title,(space)/2,45)
    context.font = "bold 30px Montserrat"
    space=context.canvas.width-context.measureText(subtitle).width
    context.fillText(subtitle,(space)/2,80)
    context.font = "bold 15px Montserrat"
    space=context.canvas.width-context.measureText(instructions).width
    context.fillText(instructions,(space)/2,120)
    context.font = "bold 15px Montserrat"
    space=context.canvas.width-context.measureText(footer).width
    context.fillText(footer,(space)/2,context.canvas.height-30)
    
    context.lineWidth=40
    context.strokeStyle="#84E095"
    context.strokeRect(0, 0, context.canvas.width, context.canvas.height)

    context.lineWidth=20
    context.strokeStyle="#f1aec9"
    context.strokeRect(0, 0, context.canvas.width, context.canvas.height)

    document.getElementById("canvasImage").src=document.getElementById("resetCanvas").toDataURL()
  }
  returnFalse(e){e.preventDefault();return false}
  render(){
  return <><canvas id="resetCanvas" ref={this.state.canvasRef} {...this.props}/><img onContextMenu={this.returnFalse} id="canvasImage" src=""></img><SaveCanvas onClick={this.saving}/><button onClick={this.reloadCanvas}>Generate New Recipe Challenge</button><form method="get" action="./">
  <button type="submit">Homepage</button>
</form></>
    }
}

export default Canvas